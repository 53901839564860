import React from "react";
import {Link, graphql } from "gatsby";
import styled, { css } from 'styled-components';
import { Purple, LightPink, LightBlue, Background } from '../styles/constants';
import Book from "../content/right-arrow.svg";
import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogPage = styled.section`
    position: absolute;
    top: 10%;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
`;

const BlogContainer = styled.section`
    width: 60%;
    @media only screen and (max-width: 850px) {
        width: 100%;
        padding: 0 1.5rem 0 1.5rem;
    }
`;
const BlogPostContainer = styled.section`
    padding-bottom: 1.5rem;
`;

const BlogPostTitle = styled.span`
    text-decoration: none;
    font-size: 2.5rem;
    font-weight: 600;
    color: ${Purple};
    @media only screen and (max-width: 430px) {
        font-size: 1.5rem;
    }
`;
const BlogPostDate = styled.section`
    padding-left: .5rem;
    padding-bottom: 1rem;
    color: ${LightPink}
`;
const BlogPostDescription = styled.div`
    font-size: 1.25rem;
    display: inline;
    color: 'white';
    @media only screen and (max-width: 430px) {
        font-size: 1rem;
    }
`;
const BookLogo = styled.img`
    height: 24px;
    weight: 24px;
    padding-left: 1rem;
    vertical-align: bottom;
    filter: invert(43%) sepia(83%) saturate(1587%) hue-rotate(184deg) brightness(101%) contrast(91%);
`;
const BlogPostDescriptionSection = styled.section`
    width: 80%;
    @media only screen and (max-width: 1050px) {
        width: 100%;
    }
`;

const Blog = (props) => {
    const { data } = props;
    const posts = data.allMarkdownRemark.edges
    const siteTitle = data.site.siteMetadata.title;

    return (
        <Layout location={props.location} title={siteTitle}>
            <SEO title="All posts" />
            <BlogPage>
                <BlogContainer>
                    {posts.map(({ node }) => {
                        const title = node.frontmatter.title || node.fields.slug
                        return (
                            <BlogPostContainer key={node.fields.slug}>
                                <BlogPostTitle>
                                    <Link style= {{color: `inherit`, 'text-decoration': `none`}} to={node.fields.slug}>
                                        {title}
                                    </Link>
                                </BlogPostTitle>
                                <BlogPostDate>{node.frontmatter.date}</BlogPostDate>
                                <BlogPostDescriptionSection>
                                    <BlogPostDescription
                                        dangerouslySetInnerHTML={{
                                            __html: node.frontmatter.description || node.excerpt,
                                        }}
                                    />
                                    <a href={node.fields.slug}>
                                        <BookLogo src={Book}/>
                                    </a>
                                </BlogPostDescriptionSection>
                            </BlogPostContainer>
                        )
                    })}
                </BlogContainer>
            </BlogPage>
        </Layout>
    );
};

export const pageQuery = graphql`
query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
            }
          }
        }
    },
    site {
        siteMetadata {
          title
        }
    }
}
`;
export default Blog;