import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Background, LightBlue, DarkGray } from '../styles/constants'
import styled, { css } from 'styled-components';

const HeaderSection = styled.section`
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 100%;
    z-index: 10;
    background: ${props => props.isScrolled ? '#004261' : Background};
    display: flex;
`;

const HeaderContainer = styled.section`
    display: flex;
    justify-content: flex-end;
    width: 50%;
    color: ${props => props.isScrolled ? 'white' : LightBlue};
`;

const HomeLinkContainer = styled.section`
    display: flex;
    width: 50%;
    font-size: 2rem;
    padding-left: 2.5%;
    color: white;

    @media only screen and (max-width: 1000px) {
        font-size: 1.5rem;
    }
`;

const Header = styled.span`
    font-size: 2rem;
    margin-right: 5%;

    @media only screen and (max-width: 1000px) {
        font-size: 1.5rem;
    }
`;

const HeaderLink = (props) => (
    <Header style={props.style}>
        <Link
            to={props.href}
            style= {{color: `inherit`, 'text-decoration': `none`}}>
            {props.children}
        </Link>
    </Header>
);

const Navbar = () => {
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY >= 15;
            if (scrollCheck != scroll) {
                setScroll(scrollCheck);
            }
        });
    });

    return (<HeaderSection isScrolled={scroll}>
                <HomeLinkContainer>
                    <HeaderLink href='/'>Home</HeaderLink>
                </HomeLinkContainer>
                <HeaderContainer isScrolled={scroll}>
                    <HeaderLink href='/blog'>Blog</HeaderLink>
                    <HeaderLink href='/resume'>Resume</HeaderLink>
                    <HeaderLink href='/contact'>Contact</HeaderLink>
                </HeaderContainer>
            </HeaderSection>);
};

export default Navbar;