import React from "react";
import { Link } from "gatsby";
import Navbar from "../components/navbar";

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`
        }}
      >
        <header><Navbar /></header>
        <main>{children}</main>
      </div>
    )
  }
}

export default Layout
