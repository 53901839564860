const Purple = '#00bcd4';
const LightPink = '#AB47BC';
const LightBlue = '#03a9f4';
const Background = '#212223';
const DarkGray = '#333030';

export {
    Purple,
    LightPink,
    LightBlue,
    Background,
    DarkGray
};